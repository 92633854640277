import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Cog6ToothIcon, XMarkIcon } from '@heroicons/react/24/outline';

const questions = [
  {
    "id": 1,
    "title": "Ex. 1",
    "content": "Explain the role of volatile in Java. How does it ensure visibility of changes across threads without guaranteeing atomicity? In contrast, how does synchronized ensure both visibility and atomicity? Can you give examples where one would be preferred over the other?"
  },
  {
    "id": 2,
    "title": "Ex. 2",
    "content": "Can you describe how the Java Garbage Collector works and the different types of garbage collection strategies (e.g., CMS, G1, ZGC)? How do they impact performance in long-running applications like Minecraft servers? and which GC will you choose to reduce impact on a Minecraft server?"
  },
  {
    "id": 3,
    "title": "Ex. 3",
    "content": "Explain your understanding of Java’s CompletableFuture class and how it differs from using synchronous code in a plugin. How would you leverage CompletableFuture in a Paper plugin to perform multiple asynchronous tasks and handle their results?"
  },
  {
    "id": 4,
    "title": "Ex. 4",
    "content": "Explain the challenges of using thread pools and asynchronous tasks in Minecraft's single-threaded architecture. What strategies would you employ to offload heavy processing without affecting the server's main thread performance?"
  },
  {
    "id": 5,
    "title": "Ex. 5",
    "content": "Have you worked with Mojang's Brigadier command framework in Paper (introduced in 1.20.6)? How does it enable more complex command structures compared to the older wrapped Bukkit command system? Can you provide examples where Brigadier commands would be beneficial?"
  },
  {
    "id": 6,
    "title": "Ex. 6",
    "content": "Describe how Java's memory model affects concurrency in Minecraft plugins. How do concepts like the Java Memory Barrier and volatile relate to the behavior of multithreaded code in the Paper API?"
  },
  {
    "id": 7,
    "title": "Ex. 7",
    "content": "What are the key differences between Java's synchronized collections (e.g., ConcurrentHashMap) and manually synchronized blocks? Can you explain when you'd prefer using one over the other in a Minecraft plugin to handle shared resources?"
  },
  {
    "id": 8,
    "title": "Ex. 8",
    "content": "In Paper API, how would you use events like PlayerMoveEvent efficiently on large player counts to minimize performance impact on the main server thread? Explain any techniques you would use to avoid unnecessary overhead."
  },
  {
    "id": 9,
    "title": "Ex. 9",
    "content": "How would you approach handling cross-server player data synchronization in a networked environment using the Paper API? Discuss your preferred libraries for real-time updates without compromising server performance."
  },
  {
    "id": 10,
    "title": "Ex. 10",
    "content": "Explain how you would handle performance optimizations for a plugin that processes large data sets (e.g., mass block edits or entity tracking). What Java features, such as parallel streams or other concurrency tools, would you use, and how would you ensure minimal impact on the main thread?"
  },
  {
    "id": 11,
    "title": "Ex. 11",
    "content": "How would you design a caching system in a Paper plugin for frequently accessed data (like world data) to minimize disk I/O while keeping memory consumption manageable? What Java tools would you use to maintain cache consistency and speed?"
  },
  {
    "id": 12,
    "title": "Ex. 12",
    "content": "Describe how CompletableFuture works in Java and how you can use it to manage asynchronous task chains. Can you show an example of how you would handle an asynchronous database query followed by a synchronous task in Paper?"
  },
  {
    "id": 13,
    "title": "Ex. 13",
    "content": "What are the performance considerations when using Java Streams API for large datasets? How would you decide between using a Stream or a for-loop for tasks such as iterating over all loaded entities in a server?"
  },
  {
    "id": 14,
    "title": "Ex. 14",
    "content": "In Paper plugins, main-thread performance is crucial for maintaining server TPS. How would you design a system to handle large, complex tasks (e.g., loading large amounts of data) without blocking the main thread? What Java concurrency tools would you use, and how would you ensure that the performance impact on the main thread remains minimal?"
  },
  {
    "id": 15,
    "title": "Ex. 15",
    "content": "How would you implement a rate-limited event system in Paper to throttle specific actions (e.g., limit how often a player can break blocks in a certain region)? What tools in Java and the Paper API would you use to achieve this?"
  },
  {
    "id": 16,
    "title": "Ex. 16",
    "content": "What is the purpose of Java’s WeakReference and SoftReference classes? How would you use these references when managing a cache in a Paper plugin to avoid out-of-memory errors while keeping frequently accessed data in memory?"
  },
  {
    "id": 17,
    "title": "Ex. 17",
    "content": "Explain how you would handle asynchronous operations when interacting with external databases (e.g., MySQL or Redis) in a Paper plugin to prevent server lag. and how would you ensure minimal main thread usage when reading the player data?"
  },
  {
    "id": 18,
    "title": "Ex. 18",
    "content": "How would you debug performance bottlenecks in a Minecraft plugin? What tools (e.g., VisualVM, Spark, async-profiler, yourkit) would you use to diagnose and address the issues?"
  }
];

const themes = {
  dark: { name: 'Dark', bg: '#1a1a1a', sidebar: '#242424', card: '#2a2a2a', text: '#ffffff', subtext: '#a0a0a0', highlight: '#3b82f6', scrollThumb: '#505050', scrollThumbHover: '#666666' },
  light: { name: 'Light', bg: '#f4f4f5', sidebar: '#ffffff', card: '#ffffff', text: '#1f2937', subtext: '#6b7280', highlight: '#3b82f6', scrollThumb: '#d4d4d4', scrollThumbHover: '#bababa' },
  solarizedDark: { name: 'Solarized Dark', bg: '#002b36', sidebar: '#073642', card: '#073642', text: '#839496', subtext: '#586e75', highlight: '#b58900', scrollThumb: '#405f73', scrollThumbHover: '#607a8a' },
  solarizedLight: { name: 'Solarized Light', bg: '#fdf6e3', sidebar: '#eee8d5', card: '#eee8d5', text: '#657b83', subtext: '#93a1a1', highlight: '#b58900', scrollThumb: '#e3dbc3', scrollThumbHover: '#c8b896' },
  nord: { name: 'Nord', bg: '#2e3440', sidebar: '#3b4252', card: '#434c5e', text: '#eceff4', subtext: '#d8dee9', highlight: '#88c0d0', scrollThumb: '#4f677b', scrollThumbHover: '#6a89a2' },
  dracula: { name: 'Dracula', bg: '#282a36', sidebar: '#44475a', card: '#44475a', text: '#f8f8f2', subtext: '#6272a4', highlight: '#ff79c6', scrollThumb: '#5a5b6d', scrollThumbHover: '#74759b' },
  gruvbox: { name: 'Gruvbox', bg: '#282828', sidebar: '#3c3836', card: '#3c3836', text: '#ebdbb2', subtext: '#a89984', highlight: '#fe8019', scrollThumb: '#70594c', scrollThumbHover: '#8b6b59' },
  monokai: { name: 'Monokai', bg: '#272822', sidebar: '#3e3d32', card: '#3e3d32', text: '#f8f8f2', subtext: '#75715e', highlight: '#f92672', scrollThumb: '#6a6351', scrollThumbHover: '#817c64' },
  github: { name: 'GitHub', bg: '#ffffff', sidebar: '#f6f8fa', card: '#ffffff', text: '#24292e', subtext: '#6a737d', highlight: '#0366d6', scrollThumb: '#b0b8c0', scrollThumbHover: '#969ea7' },
  oneDark: { name: 'One Dark', bg: '#282c34', sidebar: '#21252b', card: '#2c313a', text: '#abb2bf', subtext: '#5c6370', highlight: '#61afef', scrollThumb: '#4b5666', scrollThumbHover: '#5e6b7f' },
  material: { name: 'Material', bg: '#263238', sidebar: '#2e3c43', card: '#37474f', text: '#eceff1', subtext: '#b0bec5', highlight: '#80cbc4', scrollThumb: '#506069', scrollThumbHover: '#698489' },
  horizon: { name: 'Horizon', bg: '#1c1e26', sidebar: '#232530', card: '#2e303e', text: '#e93c58', subtext: '#6e6a86', highlight: '#f43e5c', scrollThumb: '#3f4054', scrollThumbHover: '#575776' },
  oceanicNext: { name: 'Oceanic Next', bg: '#1b2b34', sidebar: '#1c3b4a', card: '#1e3c4f', text: '#d8dee9', subtext: '#65737e', highlight: '#6699cc', scrollThumb: '#3c5a6b', scrollThumbHover: '#577f97' },
  nightOwl: { name: 'Night Owl', bg: '#011627', sidebar: '#003a60', card: '#0b2942', text: '#d6deeb', subtext: '#7fdbca', highlight: '#82aaff', scrollThumb: '#4a6789', scrollThumbHover: '#6a8fb3' },
  ayuMirage: { name: 'Ayu Mirage', bg: '#1f2430', sidebar: '#272d3e', card: '#323f55', text: '#c7c8c2', subtext: '#6b7385', highlight: '#f29718', scrollThumb: '#4c566e', scrollThumbHover: '#6b728e' },
  cyberpunk: { name: 'Cyberpunk', bg: '#0d0015', sidebar: '#190025', card: '#250039', text: '#e6e1cf', subtext: '#ff3399', highlight: '#ffcc00', scrollThumb: '#55005f', scrollThumbHover: '#77007f' },
  zenburn: { name: 'Zenburn', bg: '#3f3f3f', sidebar: '#404040', card: '#4f4f4f', text: '#dcdccc', subtext: '#709080', highlight: '#f0dfaf', scrollThumb: '#50554f', scrollThumbHover: '#6b6f68' },
  arcDark: { name: 'Arc Dark', bg: '#21252b', sidebar: '#282c34', card: '#32363e', text: '#d3dae3', subtext: '#959db3', highlight: '#5294e2', scrollThumb: '#4b5563', scrollThumbHover: '#6b7489' },
  vscode: { name: 'VS Code', bg: '#1e1e1e', sidebar: '#252526', card: '#2d2d2d', text: '#d4d4d4', subtext: '#c5c5c5', highlight: '#007acc', scrollThumb: '#484848', scrollThumbHover: '#606060' },
  highContrast: { name: 'High Contrast', bg: '#000000', sidebar: '#1b1b1b', card: '#1e1e1e', text: '#ffffff', subtext: '#c5c5c5', highlight: '#ffcc00', scrollThumb: '#333333', scrollThumbHover: '#505050' },
  cobalt: { name: 'Cobalt', bg: '#1e1e2f', sidebar: '#253e6d', card: '#2c3e50', text: '#ecf0f1', subtext: '#bdc3c7', highlight: '#e74c3c', scrollThumb: '#48556b', scrollThumbHover: '#60728a' },
  solarizedOcean: { name: 'Solarized Ocean', bg: '#073642', sidebar: '#002b36', card: '#268bd2', text: '#839496', subtext: '#586e75', highlight: '#b58900', scrollThumb: '#0f4c5c', scrollThumbHover: '#1a7482' },
  pastel: { name: 'Pastel', bg: '#f5f7fa', sidebar: '#ffffff', card: '#ffffff', text: '#34495e', subtext: '#7f8c8d', highlight: '#e67e22', scrollThumb: '#d0d3d7', scrollThumbHover: '#b0b2b5' },
  pastelDark: { name: 'Pastel Dark', bg: '#34495e', sidebar: '#2c3e50', card: '#3a3f47', text: '#ecf0f1', subtext: '#bdc3c7', highlight: '#e74c3c', scrollThumb: '#4c5460', scrollThumbHover: '#6a7381' },
  highContrastLight: { name: 'High Contrast Light', bg: '#ffffff', sidebar: '#f0f0f0', card: '#e0e0e0', text: '#000000', subtext: '#555555', highlight: '#ff0000', scrollThumb: '#d3d3d3', scrollThumbHover: '#bfbfbf' },
  forest: { name: 'Forest', bg: '#2e3a2c', sidebar: '#3b3f33', card: '#4a5b4e', text: '#e6f1e6', subtext: '#b2c3b2', highlight: '#a8cc8c', scrollThumb: '#516851', scrollThumbHover: '#6d826a' },
};

function App() {
  const [theme, setTheme] = useState('dark');
  const [showThemeMenu, setShowThemeMenu] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem('selectedTheme');
    if (storedTheme && themes[storedTheme]) {
      setTheme(storedTheme);
    }
  }, []);

  const toggleThemeMenu = () => {
    setShowThemeMenu(!showThemeMenu);
  };

  const selectTheme = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem('selectedTheme', newTheme);
    setShowThemeMenu(false);
  };

  const currentTheme = themes[theme];

  const scrollbarStyles = `
    .themed-scrollbar::-webkit-scrollbar {
      width: 8px;
    }
    .themed-scrollbar::-webkit-scrollbar-thumb {
      background-color: ${currentTheme.scrollThumb};
      border-radius: 8px;
    }
    .themed-scrollbar::-webkit-scrollbar-thumb:hover {
      background-color: ${currentTheme.scrollThumbHover};
    }
    .themed-scrollbar::-webkit-scrollbar-track {
      background: transparent;
    }
    .themed-scrollbar {
      scrollbar-width: thin;
      scrollbar-color: ${currentTheme.scrollThumb} transparent;
    }
  `;

  return (
    <div className="min-h-screen p-6 themed-scrollbar" style={{ backgroundColor: currentTheme.bg, color: currentTheme.text }}>
      <style>{scrollbarStyles}</style>
      <header className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold">Interview Questions</h1>
        <button 
          onClick={toggleThemeMenu} 
          className="p-2 rounded-full hover:bg-opacity-20 hover:bg-gray-600 transition-colors"
          aria-label="Toggle theme menu"
        >
          <Cog6ToothIcon className="w-6 h-6" />
        </button>
      </header>

      <main className="max-w-3xl mx-auto">
        {questions.map((q) => (
          <div key={q.id} className="mb-8">
            <h2 className="text-xl font-bold mb-4" style={{ color: currentTheme.highlight }}>{q.title}</h2>
            <div className="p-6 rounded-lg shadow-lg" style={{ backgroundColor: currentTheme.card }}>
              <p className="leading-relaxed">{q.content}</p>
            </div>
          </div>
        ))}
      </main>

      <AnimatePresence>
        {showThemeMenu && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={toggleThemeMenu}
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-sm w-full max-h-[80vh] overflow-y-auto themed-scrollbar"
              onClick={(e) => e.stopPropagation()}
              style={{ backgroundColor: currentTheme.card, color: currentTheme.text }}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">Select Theme</h3>
                <button onClick={toggleThemeMenu} className="text-gray-500 hover:text-gray-700">
                  <XMarkIcon className="w-6 h-6" />
                </button>
              </div>
              {Object.entries(themes).map(([key, value]) => (
                <button
                  key={key}
                  onClick={() => selectTheme(key)}
                  className="block w-full text-left py-2 px-4 rounded hover:bg-opacity-20 hover:bg-gray-600 transition-colors"
                >
                  {value.name}
                </button>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;